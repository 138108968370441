.modal-wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    background: rgba(58,61,71,.79);
    z-index: 501;
}

.modal-close {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 2rem;
    display: none;
}

.modal-close-mobile {
    background-color: #fff;
    padding: 1.25rem 2rem;
}

.modal-close-mobile button {
    width: 100%;
    background-color: #fff;
    color: #080808;
    border: none;
    box-shadow: inset 0 0 0 1px #eee;
    padding: 8px 13px;
    font-weight: 400;
    font-size: 1rem;
    border-radius: 3px;
}

.modal-wrapper-inner {
    position: relative;
    width: 100%;
    background-color: #fff;
    z-index: 100;
}

.modal-wrapper-inner .icon-close {
    position: absolute;
    top: 0;
    right: -2rem;
    cursor: pointer;
    display: none;
}

.modal-wrapper-inner .icon-close span {
    display: block;
    background: url('../../img/close-icon.png') center center no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
}

.modal-wrapper-inner figure {
    position: relative;
    margin: 0;
    width: 100%;
    height: auto;
}

.modal-wrapper-inner figure .products-count {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    width: 28px;
    line-height: 28px;
    background-color: #2b303b;
    border-radius: 50%;
    text-align: center;
    font-size: 14px;
    color: #fff;
    box-shadow: 0 0 0 2px rgba(255,255,255,.7),0 0 0 4px #2b303b;
}

.modal-wrapper-inner figure img {
    width: 100%;
    display: block;
}

.modal-wrapper-inner .products-info {
    width: 100%;
}

.modal-wrapper-inner .products-info .products-images {
    padding: 1rem 1rem 2rem;
    display: flex;
    align-items: center;
}

.modal-wrapper-inner .products-info .products-images > div {
    margin-right: 0.75rem;
    cursor: pointer;
}

.modal-wrapper-inner .products-info .products-images > div.active picture {
    border-color: #080808;
    border-width: 3px;
}

.modal-wrapper-inner .products-info .products-images > div picture {
    border: 1px solid rgba(0,0,0,.03);
    display: block;
    width: 85px;
}

.modal-wrapper-inner .products-info .products-images > div.active img {
    padding: 2px;
}

.modal-wrapper-inner .products-info .products-images > div img {
    display: block;
    width: 100%;
}

.modal-wrapper-inner .products-info .product-detail {
    position: relative;
    padding: 1rem;
    border-top: 1px solid #f3f3f3;
    width: 100%;
}

.modal-wrapper-inner .products-info .product-detail-lead {
    top: 0;
    right: 0;
    left: 0;
    overflow-y: scroll;
    padding: 0 0 45px;
}

.modal-wrapper-inner .products-info .title {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
    color: #080808;
    margin: 0 0 .5rem;
}

.modal-wrapper-inner .products-info .price {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0 0 1.5rem;
}

.modal-wrapper-inner .products-info .color-title,
.modal-wrapper-inner .products-info .size-title {
    font-size: 1.1rem;
    line-height: 1;
    font-weight: 500;
    color: #080808;
    margin: 0 0 .5rem;
}

.modal-wrapper-inner .products-info .colors {
    display: flex;
    flex-wrap: wrap;
}

.modal-wrapper-inner .products-info .single-color {
    position: relative;
    margin: 0 1rem 1rem 0;
}

.modal-wrapper-inner .products-info .single-color input[type=radio] {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
}

.modal-wrapper-inner .products-info .single-color picture {
    display: block;
    margin: 0;
    border: 3px solid transparent;
    cursor: pointer;
}

.modal-wrapper-inner .products-info .single-color picture img {
    display: block;
    width: 50px;
    height: auto;
}

.modal-wrapper-inner .products-info .single-color input[type=radio]:checked + picture {
    border-color: #080808;
}

.modal-wrapper-inner .products-info .sizes {
    margin-bottom: 1rem;
}

.modal-wrapper-inner .product-description .description-title {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.025rem;
    margin: 0 0 10.5px;
}

.modal-wrapper-inner .product-description p:not(.description-title) {
    font-size: 1rem;
    line-height: 125%;
    margin: 0 0 10.5px;
}

.modal-wrapper-inner .product-detail-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 1rem;
    background-color: #fff;
    border-top: 1px solid #f3f3f3;
    box-shadow: 0 -1px 1px rgba(0,0,0,.1);
}

.modal-wrapper-inner .product-detail-footer .add-to-cart {
    display: block;
    width: 100%;
    background-color: #080808;
    color: #fff;
    border: none;
    border-radius: 3px;
    padding: 8px 13px;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
}

@media(min-width:992px) {
    .modal-close-mobile {
        display: none;
    }

    .modal-close,
    .modal-wrapper-inner .icon-close {
        display: block;
    }

    .modal-wrapper {
        flex-direction: row;
        padding: 60px 0;
    }

    .modal-wrapper-inner {
        display: flex;
        max-width: 870px;
        min-height: 450px;
        margin: auto;
    }

    .modal-wrapper-inner figure {
        width: 57.5%;
    }

    .modal-wrapper-inner .products-info {
        display: flex;
        width: 42.5%;
    }

    .modal-wrapper-inner .products-info .products-images {
        flex-direction: column;
    }

    .modal-wrapper-inner .products-info .products-images > div {
        margin-right: 0;
        margin-bottom: 2rem;
    }
    
    .modal-wrapper-inner .products-info .product-detail {
        border-top: none;
        border-left: 1px solid #f3f3f3;
    }

    .modal-wrapper-inner .products-info .product-detail-lead {
        position: absolute;
        height: calc(100% - 59px);
        padding: 1rem 1rem 59px;
    }

    .modal-wrapper-inner .product-detail-footer {
        position: absolute;
    }
}