.lead-image-wrapper {
    margin-bottom: 2.904rem;
}

.lead-image-wrapper a {
    display: block;
    cursor: pointer;
}

.lead-image-wrapper figure {
    height: 400px;
    width: 100%;
    margin: 0 0 3px;
    overflow: hidden;
}

.lead-image-wrapper figure img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 0;
}

.lead-image-wrapper .caption-wrapper {
    position: relative;
    padding: 20px 60px 20px 1rem;
    border-bottom: 1px solid #eee;
}

.lead-image-wrapper .caption-wrapper p {
    font-size: 1.25rem;
    line-height: 1.4;
    color: #080808;
    margin: 0;
}

.lead-image-wrapper .caption-wrapper .arrow-right {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
}

.lead-image-wrapper figure {
    position: relative;
}

.lead-image-wrapper .cart-icon {
    position: absolute;
    bottom: .8rem;
    left: 1.2rem;
    background: url('../../img/cart-icon.png') center center no-repeat;
    background-size: contain;
    width: 21px;
    height: 21px;
}

@media(min-width:992px) {
    .lead-image-wrapper .caption-wrapper .arrow-right {
        right: 0;
        width: auto;
    }
}