.iframe-wrapper {
    position: fixed;
    top: 0;
    left: -500px;
    max-width: 500px;
    width: 100%;
    max-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 2px solid black;
    z-index: 503;
    transition: .7s all ease;
}

.iframe-wrapper.open {
    left: 0;
}

.iframe-wrapper .close-btn {
    display: table;
    border: none;
    background: none;
    width: 25px;
    height: 25px;
    position: relative;
    padding: 0;
    cursor: pointer;
    margin: 5px 5px 5px auto;
}

.iframe-wrapper .close-btn::before,
.iframe-wrapper .close-btn::after {
    content: "";
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #080808;
    height: 3px;
    border-radius: 2px;
}

.iframe-wrapper .close-btn::before {
    transform: rotate(45deg);
}

.iframe-wrapper .close-btn::after {
    transform: rotate(-45deg);
}

.iframe-wrapper iframe {
    background-color: #fff;
}

@media(min-width: 501px) {
    .iframe-wrapper {
        border: none;
        border-right: 2px solid black;
    }
}