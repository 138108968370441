.cart-wrapper .cart-button {
    position: fixed;
    right: 0;
    top: 18%;

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #2b303b;
    color: #fff;
    padding: 1rem;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
    z-index: 502;
}

.cart-wrapper .cart-button .cart-count {
    font-size: 14.3px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 1rem;
}

.cart-wrapper .cart-button img {
    width: 20px;
}

.cart-wrapper .cart-overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0);
    z-index: 0;
    transition: .4s all ease;
    z-index: 503;
}

.cart-wrapper .cart-overlay.visible {
    visibility: visible;
    opacity: 1;
    background: rgba(0,0,0,.6);
}

.cart-wrapper .cart-container {
    position: fixed;
    right: -100%;
    top: 0;

    width: 100%;
    background-color: #fff;
    height: 100%;
    transition: .4s all ease;
    z-index: 504;
}

.cart-wrapper .cart-container.open {
    right: 0;
}

.cart-container .cart-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 1.1rem 1.5rem 1.1rem 2.1rem;
}

.cart-container .cart-header h2 {
    font-weight: 500;
    font-size: 19.5px;
    line-height: .9;
    margin: 0;
}

.cart-container .cart-header .close-btn {
    border: none;
    background: none;
    width: 25px;
    height: 25px;
    position: relative;
    padding: 0;
    cursor: pointer;
}

.cart-container .cart-header .close-btn::before,
.cart-container .cart-header .close-btn::after {
    content: "";
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #080808;
    height: 3px;
    border-radius: 2px;
}

.cart-container .cart-header .close-btn::before {
    transform: rotate(45deg);
}

.cart-container .cart-header .close-btn::after {
    transform: rotate(-45deg);
}

.cart-container .cart-products {
    position: relative;
    padding: 2rem;
    margin: 0;
    overflow-y: auto;
    height: 100%;
    max-height: calc(100vh - 147.5px);
}

.cart-container .cart-footer {
    padding: 1rem 2rem;
    border-top: 1px solid #eee;
}

.cart-container .cart-footer .price-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.1;
    padding-bottom: 1rem;
}

.cart-container .cart-footer .price-info .info {
    display: block;
    font-size: 15px;
}

.cart-container .cart-footer .price-info .price {
    display: block;
    font-size: 16px;
}

.cart-container .cart-footer .btn-proceed {
    background-color: #50b83c;
    border: none;
    border-radius: 3px;
    width: 100%;
    color: #fff;
    padding: 8px 13px;
    font-weight: 400;
    font-size: 14.3px;
    line-height: 19.3px;
    cursor: pointer;
}

.cart-container .cart-footer .btn-proceed.disabled {
    opacity: .3;
    cursor: auto;
}

.cart-item {
    display: flex;
    margin-bottom: 2rem;
}

.cart-item:last-child {
    margin-bottom: 0;
}

.cart-item .item-image {
    margin-right: 10px;
}

.cart-item .item-image picture {
    display: block;
    width: 65px;
    height: 65px;
    border: 1px solid #eee;
    border-radius: 3px;
    background-color: #f9fafc;
}

.cart-item .item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cart-item .item-content {
    width: 100%;
}

.cart-item .item-content .content-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    position: relative;
    margin-bottom: 5px;
}

.cart-item .item-content .item-title {
    display: block;
    color: #080808;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    margin: 0;
    padding-right: 2rem;
}

.cart-item .item-content .remove-item {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 5px;
    top: 0;
    width: 12px;
    padding: 0;
    cursor: pointer;
}

.cart-item .item-content .remove-item img {
    width: 100%;
}

.cart-item .item-content .quantity {
    display: flex;
    align-items: center;
    border: 1px solid #eee;
    border-radius: 3px;
}

.cart-item .item-content .quantity .quantity-num {
    color: #080808;
    width: 38px;
    height: 21px;
    line-height: 22px;
    font-size: 14px;
    font-weight: 500;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    text-align: center;
}

.cart-item .item-content .quantity button {
    color: #999;
    display: block;
    width: 25px;
    height: 21px;
    font-size: 18px;
    text-align: center;
    line-height: 16px;
    padding: 0;
    border: none;
    background: none;
    box-shadow: none;
    outline: 0;
    cursor: pointer;
}

.cart-item .item-content .item-price,
.cart-item .item-content .times {
    font-size: 15px;
    line-height: 23px;
    font-weight: 500;
    margin-left: 1rem;
    color: #080808;
}

@media(min-width:992px) {
    .cart-wrapper .cart-button {
        top: 50%;
        transform: translateY(-50%);
        padding: 1.25rem;
    }
    
    .cart-wrapper .cart-button .cart-count {
        font-size: 1.5rem;
    }

    .cart-wrapper .cart-container {
        right: -370px;
        width: 370px;
    }
}