.images-wrapper .image-card {
    position: relative;
    max-width: calc(33.33333% - 2px);
    width: 100%;
    margin: 1px;
    cursor: pointer;
}

.images-wrapper .image-card::after {
    content: '';
    display: block;
    padding-top: 100%;
}

.images-wrapper .image-card:hover {
    opacity: .8;
}

.images-wrapper .image-card > a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.images-wrapper .image-card > a figure {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    background: #f3f3f3;
    overflow: hidden;
}

.images-wrapper .image-card > a figure img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: 50% 50%;
}

.images-wrapper .image-card .cart-icon {
    position: absolute;
    bottom: .4rem;
    left: .4rem;
    background: url('../../img/cart-icon.png') center center no-repeat;
    background-size: contain;
    width: 21px;
    height: 21px;
}