header {
    background-color: #fff;
    padding: .437rem 0;
    border-bottom: 1px solid #ddd;
    height: 55px;
}

header .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 .75rem;
}

header .header-wrapper .store-name {
    display: flex;
    align-items: center;
}

header .header-wrapper .store-name a {
    display: flex;
    align-items: center;
    max-width: 40px;
    height: 40px;
    width: 100%;
    border-radius: 50%;
    border: 1px solid #eee;
    margin-right: 10px;
}

header .header-wrapper .store-name a picture {
    display: block;
}

header .header-wrapper .store-name a picture img {
    display: block;
    width: 100%;
}

header .header-wrapper .store-name h1 {
    font-size: 1.3rem;
    font-weight: 500;
    color: #080808;
}

header .btn-link {
    display: table;
    min-width: 150px;
    font-size: 1.1rem;
    line-height: 30px;
    color: #080808;
    border: 1px solid #080808;
    border-radius: 3px;
    text-align: center;
    padding: 0 10px;
    text-decoration: none;
}

@media(min-width:1000px) {
    header .btn-link {
        min-width: 200px;
    }
}