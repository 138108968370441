*, *:active, *:focus, *:hover {
    outline: none;
}

*, ::after, ::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html, body {
    background-color: #fafafa;
    margin: 0;
}

html {
    font-size: 13px;
}

body {
    position: relative;
    overflow-x: hidden;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    min-height: 100vh;
}

.container {
    position: relative;
    max-width: 860px;
    margin: 0 auto;
}